


















import { Component, Prop, Vue } from 'vue-property-decorator';
import api from '@/sdk/api/Api.ts';

@Component({
  components: {
    InsLogo: () => import('@/components/base/InsLogo.vue'),
    InsLangSwitch: () => import('@/components/business/header/InsLangSwitch.vue'),
    InsSearch: () => import('@/components/business/header/InsSearch.vue'),
    InsMenu: () => import('@/components/business/header/InsMenu.vue')
  }
})
export default class InsHeader extends Vue {
  private menuShow: boolean = false;
  fixSta: boolean = false;

  getMenu () {
    this.$Api.promotion
      .getMenu()
      .then(result => {
        // 去除原来37届人物列表
        result.ReturnValue.HeaderMenus.forEach((item, index) => {
          if (item.Childs && item.Childs.length) {
            item.Childs.forEach((v, i) => {
              if (v.Value.Id === '40131') {
                item.Childs.splice(i, 1);
              }
            });
          }
        });
        console.log(result, '菜单000000000000000');
        this.$store.dispatch('setHeaderMenus', result.ReturnValue.HeaderMenus);
        this.$store.dispatch('setFooterMenus', result.ReturnValue.FooterMenus);
      })
      .catch(error => {
        console.log(error);
      });
  }

  showSlideMenu () {
    this.menuShow = true;
    this.$store.dispatch('isShowMenu', this.menuShow);
  }
  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.getMenu();
  }
}
